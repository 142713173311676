import axiosCrm from '@/libs/axios-crm'

const getAxios = () => axiosCrm.get('/vendorSetTypes')
const postAxios = data => axiosCrm.post('/vendorSetTypes', data)
const oneAxios = id => axiosCrm.get(`/vendorSetTypes/${id}`)
const putAxios = (id, data) => axiosCrm.put(`/vendorSetTypes/${id}`, data)
const deleteAxios = id => axiosCrm.delete(`/vendorSetTypes/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
}
